





























import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import { OutputOrganizationDTO } from '@/api/api';
import LocationList from './LocationList.vue';
import OrganizationStatusIcon from "@/components/OrganizationStatusIcon.vue";
import Loading from "@/components/Loading.vue";
// import AlarmListCard from './AlarmListCard.vue';
// import EventListCard from './EventListCard.vue';

@Component({
  components: {
    Loading,
    OrganizationStatusIcon,
    // AlarmListCard,
    // EventListCard,
    LocationList,
  },
})
export default class OrganisationView extends Vue {
  @Prop(Number) readonly organizationId;

  public valid = true;
  // public status: Status = {
  //   ok: undefined,
  //   warning: undefined,
  //   error: undefined,
  // };
  public organization: OutputOrganizationDTO|null = null;

  public async mounted() {
    this.organization = await this.$store.dispatch('organizations/get', { organizationId: this.organizationId })
  }

  public close() {
    this.$router.back();
  }

  get loading() {
    return this.organization === null;
  }
}
