<template>
  <v-icon v-if="!organizationData" color="grey">pending</v-icon>
  <v-icon v-else-if="organizationData.status === 'ERROR'" color="error">error</v-icon>
  <v-icon v-else-if="organizationData.status === 'WARNING'" color="warning">warning</v-icon>
  <v-icon v-else-if="organizationData.status === 'OK'" color="success">check_circle</v-icon>
  <v-icon v-else color="grey">pending</v-icon>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class OrganizationStatusIcon extends Vue {
  @Prop() organizationData;
}
</script>

<style scoped>

</style>