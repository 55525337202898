<template>
  <v-icon v-if="!locationData" color="grey">pending</v-icon>
  <v-icon v-else-if="locationData.device_statuses.error > 0" color="error">error</v-icon>
  <v-icon v-else-if="locationData.device_statuses.warning > 0 || locationData.device_statuses.unknown || locationData.device_statuses.unmapped" color="warning">warning</v-icon>
  <v-icon v-else color="success">check_circle</v-icon>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LocationStatusIcon extends Vue {
  @Prop() locationData;
}
</script>

<style scoped>

</style>
