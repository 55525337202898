
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {OutputLocationDTO} from '@/api/api';
import LocationStatusIcon from "@/components/LocationStatusIcon.vue";
@Component({
  components: {LocationStatusIcon}
})
export default class LocationList extends Vue {
  @Prop(Number) readonly organizationId;

  public headers = [
    {
      text: 'Status Indicator',
      sortable: true,
      value: 'statusIcon',
      align: 'center',
    },
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    // {
    //   text: 'Category',
    //   sortable: true,
    //   value: 'category',
    //   align: 'left',
    // },
    {
      text: 'Latitude',
      sortable: true,
      value: 'latitude',
      align: 'right',
    },
    {
      text: 'Longitude',
      sortable: true,
      value: 'longitude',
      align: 'right',
    },
    {
      text: 'Status Ok',
      sortable: 'true',
      value: 'device_statuses.ok',
      align: 'left',
    },
    {
      text: 'Status Warning',
      sortable: 'true',
      value: 'device_statuses.warning',
      align: 'left',
    },
    {
      text: 'Status Error',
      sortable: 'true',
      value: 'device_statuses.error',
      align: 'left',
    },
    {
      text: 'Status Unknown',
      sortable: 'true',
      value: 'device_statuses.unknown',
      align: 'left',
    },
    {
      text: 'Status Unmapped',
      sortable: 'true',
      value: 'device_statuses.unmapped',
      align: 'left',
    },
    // {
    //   text: 'Subscription',
    //   sortable: 'true',
    //   value: 'subscription',
    //   align: 'left',
    // },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];

  public locations: OutputLocationDTO[]|null = null;
  // public subscriptions: Subscription[] = [];
  public subscriptions = [];
  public subscribedLocations: string[] = [];

  public async mounted() {
    await this.fetchLocations();
    // this.fetchSubscriptions();
  }

  public async changeLocationSubscription(locationId: string) {
    // // query data fo
    // const user = readUserProfile(this.$store)!;
    // let subscription = this.getSubscriptionOfLocation(locationId);
    // if (subscription === undefined) {
    //   subscription = await createSubscription(
    //       this.$store,
    //       locationId,
    //       user.id!,
    //   );
    //   if (subscription !== undefined) {
    //     this.subscriptions.push(subscription!);
    //   }
    // } else {
    //   await deleteSubscription(this.$store, subscription!.id);
    //   this.subscriptions = this.subscriptions.filter(
    //       (x) => x.id !== subscription!.id,
    //   );
    // }
    // this.fetchSubscriptions();
  }

  public async fetchLocations() {
    this.locations = await this.$store.dispatch('organizations/getLocations', {organizationId: this.organizationId})
  }

  public async fetchSubscriptions() {
    // const user = readUserProfile(this.$store)!;
    // const data = await querySubscriptions(this.$store, user.id!);
    // this.subscriptions = data ? data : [];
    // this.subscribedLocations = [];
    // for (const s of this.subscriptions) {
    //   this.subscribedLocations.push(s.location_id);
    // }
  }

  public getSubscriptionOfLocation(
      locationId: string,
  ) {
    // ): Subscription | undefined {
    //   for (const s of this.subscriptions) {
    //     if (s.location_id === locationId) {
    //       return s;
    //     }
    //   }
    //   return undefined;
  }

  get loading() {
    return this.locations === null;
  }
}
