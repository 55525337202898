var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Locations")]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.locations || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var item = ref.item;
return [_c('LocationStatusIcon',{attrs:{"location-data":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
              name: 'main-maintenance-location-view',
              params: {
                organizationId: item.organization_id,
                locationId: item.id,
              },
            }}},[_c('v-icon',[_vm._v("pageview")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }